import docReady from '@/js/helpers/doc-ready'
import createSlider from '@/js/helpers/create-slider'

const baseArgs = {
  percentPosition: true,
  pageDots: true,
  prevNextButtons: true,
  wrapAround: true
}

docReady(() => {
  Array.from(document.querySelectorAll('.watch-slider')).forEach(el => {
    createSlider(el, {
      ...baseArgs,
      watchCSS: true,
      percentPosition: false,
      pageDots: false,
      prevNextButtons: false,
      wrapAround: false,
      groupCells: true,
      freeScroll: true
      // autoPlay: 3000,
      // draggable: false,
      // accessibility: false
    })
  })

  Array.from(document.querySelectorAll('.l-hero__slider')).forEach(el => {
    createSlider(el, {
      ...baseArgs,
      percentPosition: true,
      adaptiveHeight: true,
      prevNextButtons: false,
      pageDots: false,
      wrapAround: true,
      // groupCells: '80%'
      groupCells: true,
      autoPlay: 5000,
      fade: true,
      draggable: false,
      accessibility: false
    })
  })

  Array.from(document.querySelectorAll('.l-gallery .l-gallery__slider')).forEach(el => {
    createSlider(el, {
      ...baseArgs,
      pageDots: false,
      percentPosition: false,
      prevNextButtons: false,
      wrapAround: false,
      groupCells: false,
      cellAlign: 'left',
      freeScroll: true
    })
  })
})
