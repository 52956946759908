import docReady from '@/js/helpers/doc-ready'
import debounce from '@/js/helpers/debounce'
import { getPosts } from './ajax'
import gsap from 'gsap'

let isActive = false
const cb = () => {
  isActive = false
}

class SearchOverlay {
  constructor () {
    this.overlay = document.getElementById('search-overlay')
    this.form = document.getElementById('search-form')
    this.search = document.getElementById('search')
    this.results = document.getElementById('search-results')
    this.togglers = document.querySelectorAll('[data-toggle-search]')
    this.active = false

    this.initEvents()
  }

  toggleOverlay () {
    // this.overlay.classList.toggle('search-overlay--active')
    document.body.classList.toggle('search-active')
    this.active = !this.active
    const tl = gsap.timeline()

    if (this.active) {
      tl.set(this.overlay, {
        opacity: 0,
        display: 'block',
        y: 50
      })

      tl.to(this.overlay, {
        duration: 0.2,
        ease: 'power3.out',
        opacity: 1,
        y: 0,
        onComplete: () => {
          this.search.focus()
        }
      })
    } else {
      tl.to(this.overlay, {
        duration: 0.3,
        ease: 'power3.out',
        opacity: 0,
        y: 50,
        onComplete: () => {
          tl.set(this.overlay, {
            display: 'none'
          })
        }
      })
    }
  }

  searchFor (s) {
    if (!isActive) {
      getPosts({
        action: 'search_in_all',
        s
      }, this.results, cb)
    }
  }

  submitForm (e) {
    e.preventDefault()
    this.searchFor(this.search.value)
  }

  initEvents () {
    const clickHandler = () => this.toggleOverlay()
    Array.from(this.togglers).forEach(el => {
      el.addEventListener('click', clickHandler)
    })

    const submitHandler = e => this.submitForm(e)

    const inputHandler = debounce((e) => this.searchFor(e.target.value), 200)

    this.search.addEventListener('input', inputHandler)
    this.search.addEventListener('paste', inputHandler)
    this.search.addEventListener('keyup', inputHandler)

    this.form.addEventListener('submit', submitHandler)
  }
}

docReady(() => {
  // eslint-disable-next-line
  const search = new SearchOverlay()
})
