import docReady from '@/js/helpers/doc-ready'

docReady(() => {
  const qa = document.querySelectorAll('.qa')

  if (qa.length) {
    for (let i = 0; i < qa.length; i++) {
      const qHeader = qa[i].querySelector('.qa__header')
      qHeader.addEventListener('click', () => {
        qa[i].classList.toggle('qa--active')
      })
    }
  }
})
