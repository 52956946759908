import docReady from '@/js/helpers/doc-ready'
import gsap from 'gsap'

const regularAnimation = el => {
  gsap.to(el, {
    ease: 'power3.out',
    duration: 0.8,
    opacity: 1,
    y: 0,
    onComplete: () => {
      gsap.set(el, {
        clearProps: 'all'
      })
      el.classList.add('s--complete')
    }
  })
}

const regularObserver = new window.IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      const el = entry.target
      observer.unobserve(el)
      regularAnimation(el)
    }
  })
})

const slantedAnimation = el => {
  gsap.to(el, {
    ease: 'expo.out',
    duration: 1,
    opacity: 1,
    x: '-50%'
  })
}

const slantedObserver = new window.IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      const el = entry.target
      observer.unobserve(el)
      slantedAnimation(el)
    }
  })
})

const featuredProductionAnimation = el => {
  const tl = gsap.timeline()
  const panel = el.querySelector('.l-featured-production__panel')
  const foregroundImage = el.querySelector('.l-featured-production__foreground-image')
  const mainContent = el.querySelector('.l-featured-production__content-col')
  const isMobile = window.innerWidth < 767

  tl.to(panel, {
    ease: 'expo.out',
    duration: 1,
    x: 0
  }, 0)

  tl.to(mainContent, {
    ease: 'power3.out',
    duration: 0.8,
    y: 0,
    opacity: 1
  }, 0.8)

  tl.to(foregroundImage, {
    ease: 'power3.out',
    duration: 1.4,
    opacity: 1
  }, 0.6)

  if (!isMobile) {
    tl.to(foregroundImage, {
      ease: 'expo.out',
      duration: 10,
      scale: 1.2
    }, 0.6)
  }
}

const featuredProductionObserver = new window.IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      const el = entry.target
      observer.unobserve(el)
      featuredProductionAnimation(el)
    }
  })
})

docReady(() => {
  const regularAnimEls = document.querySelectorAll('.s')
  const slantedEls = document.querySelectorAll('.bg-shape')
  const featuredProductionEls = document.querySelectorAll('.l-featured-production')

  if ('IntersectionObserver' in window) {
    regularAnimEls.forEach(item => {
      gsap.set(item, {
        opacity: 0,
        y: 50
      })

      regularObserver.observe(item)
    })

    slantedEls.forEach(item => {
      gsap.set(item, {
        opacity: 0,
        x: '-100%'
      })

      slantedObserver.observe(item)
    })

    featuredProductionEls.forEach(item => {
      const panel = item.querySelector('.l-featured-production__panel')
      const foregroundImage = item.querySelector('.l-featured-production__foreground-image')
      const mainContent = item.querySelector('.l-featured-production__content-col')

      gsap.set(mainContent, {
        opacity: 0,
        y: 50
      })
      gsap.set(panel, {
        x: '-100%'
      })
      gsap.set(foregroundImage, {
        opacity: 0,
        scale: 1
      })

      featuredProductionObserver.observe(item)
    })
  } else {
    regularAnimEls.forEach(item => {
      regularAnimation(item)
    })

    slantedEls.forEach(item => {
      slantedAnimation(item)
    })

    featuredProductionEls.forEach(item => {
      featuredProductionAnimation(item)
    })
  }
})
