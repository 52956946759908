import 'intersection-observer'

// Modernizr
import 'root/.modernizrrc'

// Stylesheets
import '@/scss/style'

// Scripts
import '@/js/components/vh'
import '@/js/components/navigation'
import '@/js/components/ajax'
import '@/js/components/splash'
import '@/js/components/animate'
import '@/js/components/copy'
import '@/js/components/search'
import '@/js/components/plyr'
import '@/js/components/slider'
import '@/js/components/lazy'
import '@/js/components/qa'
