const loadImages = (image) => {
  const src = image.getAttribute('data-src')
  const srcset = image.getAttribute('data-srcset')
  const sizes = image.getAttribute('data-sizes')

  image.setAttribute('src', image.getAttribute('data-src'))

  image.onload = () => {
    image.removeAttribute('data-src')
    image.removeAttribute('data-srcset')
    image.removeAttribute('data-sizes')
    image.classList.remove('not-loaded')
    image.classList.add('loaded')
  }

  image.setAttribute('src', src)
  image.setAttribute('srcset', srcset)
  image.setAttribute('sizes', sizes)
}

const imagesToLoad = document.querySelectorAll('img[data-src]')

if ('IntersectionObserver' in window) {
  // eslint-disable-next-line
  const observer = new IntersectionObserver((items, observer) => {
    items.forEach((item) => {
      if (item.isIntersecting) {
        loadImages(item.target)
        observer.unobserve(item.target)
      }
    })
  })
  imagesToLoad.forEach((img) => {
    observer.observe(img)
  })
} else {
  imagesToLoad.forEach((img) => {
    loadImages(img)
  })
}
