import docReady from '@/js/helpers/doc-ready'
import gsap from 'gsap/gsap-core'
import Plyr from 'plyr'

docReady(() => {
  const players = document.querySelectorAll('.b__player')
  const togglers = document.querySelectorAll('[data-toggle-video]')
  const jwWrappers = document.querySelectorAll('.jw-wrap')

  if (players.length) {
    for (let i = 0; i < players.length; i++) {
      // eslint-disable-next-line
      const player = new Plyr(players[i], {
        controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
        fullscreen: { enabled: true, fallback: true, iosNative: true, container: null }
      })
    }
  }

  Array.from(jwWrappers).forEach(el => {
    const jwPlayerEl = el.querySelector('.b__jw')
    const jwPlayerTogglers = el.querySelectorAll('[data-toggle-jw]')

    Array.from(jwPlayerTogglers).forEach(toggler => {
      const jwRef = toggler.getAttribute('data-toggle-jw')
      const overlayEl = document.querySelector(`[data-overlay="${jwRef}"]`)
      const figureEl = document.querySelector(`[data-figure="${jwRef}"]`)
      const videoWrap = document.querySelector(`[data-wrap="${jwRef}"]`)
      const isLoaded = false
      const tl = gsap.timeline()

      let player

      toggler.addEventListener('click', () => {
        if (!isLoaded) {
          player = window.jwplayer(jwPlayerEl.id)

          player.setup({
            file: jwPlayerEl.getAttribute('data-url')
          })
        }

        overlayEl.parentNode.parentNode.classList.add('is-playing')
        toggler.classList.add('b__button--hidden')

        tl.to(overlayEl, {
          duration: 0.8,
          ease: 'power3.out',
          height: videoWrap.offsetHeight,
          onComplete: () => {
            if (overlayEl) {
              overlayEl.classList.add('b__overlay--hidden')
            }

            if (figureEl) {
              figureEl.classList.add('b__figure--hidden')
            }

            setTimeout(() => {
              if (overlayEl) {
                overlayEl.classList.add('b__overlay--gone')
              }

              gsap.set(videoWrap, {
                position: 'relative'
              })

              if (figureEl) {
                figureEl.classList.add('b__figure--gone')
              }

              player.play()
            }, 200)
          }
        })
      })
    })
  })

  Array.from(togglers).forEach(el => {
    el.addEventListener('click', () => {
      const videoRef = el.getAttribute('data-toggle-video')
      const videoEl = document.querySelector(`[data-video="${videoRef}"]`)
      const overlayEl = document.querySelector(`[data-overlay="${videoRef}"]`)
      const figureEl = document.querySelector(`[data-figure="${videoRef}"]`)
      const videoWrap = document.querySelector(`[data-wrap="${videoRef}"]`)
      const tl = gsap.timeline()

      overlayEl.parentNode.parentNode.classList.add('is-playing')
      el.classList.add('b__button--hidden')

      tl.to(overlayEl, {
        duration: 0.8,
        ease: 'power3.out',
        height: videoWrap.offsetHeight,
        onComplete: () => {
          if (overlayEl) {
            overlayEl.classList.add('b__overlay--hidden')
          }

          if (figureEl) {
            figureEl.classList.add('b__figure--hidden')
          }

          setTimeout(() => {
            if (overlayEl) {
              overlayEl.classList.add('b__overlay--gone')
            }

            gsap.set(videoWrap, {
              position: 'relative'
            })

            if (figureEl) {
              figureEl.classList.add('b__figure--gone')
            }

            videoEl.play()
          }, 200)
        }
      })
    })
  })
})
