import docReady from '@/js/helpers/doc-ready'
import gsap from 'gsap'
import emptyWrapper from '@/js/helpers/empty-wrapper'

const axios = require('axios')
let isActive = false

const cb = () => {
  isActive = false
}

export const getPosts = (params, wrapper, cb) => {
  gsap.fromTo(wrapper, {
    opacity: 1
  }, {
    opacity: 0.8,
    duration: 0.2,
    onComplete: () => {
      axios
        // eslint-disable-next-line
        .get(ajaxurl, {
          params
        })
        .then(res => res.data)
        .then(data => {
          gsap.set(wrapper, {
            opacity: 1
          })

          emptyWrapper(wrapper)

          if (data) {
            wrapper.insertAdjacentHTML('beforeEnd', data)
          }

          const articles = wrapper.querySelectorAll('article')

          if (articles && articles.length) {
            gsap.fromTo(articles, {
              opacity: 0,
              y: 10
            }, {
              opacity: 1,
              y: 0,
              duration: 0.4,
              stagger: 0.05
            })
          }
        })
        .catch(error => {
          // handle error
          console.log(error)
        })
        .then(cb)
    }
  })
}

const changeType = function (e, buttons, i, wrapper) {
  const curr = e.currentTarget
  if (curr.classList.contains('button--active') || isActive) {
    return
  }

  isActive = true

  for (let j = 0; j < buttons.length; j++) {
    if (i !== j) {
      buttons[j].classList.remove('button--active')
    } else {
      buttons[j].classList.add('button--active')
    }
  }

  const termType = curr.getAttribute('data-type-button')
  const postType = curr.getAttribute('data-post-type-button')
  const taxonomy = curr.getAttribute('data-taxonomy')

  getPosts({
    action: 'vacancies_list',
    termType,
    postType,
    taxonomy
  }, wrapper, cb)
}

const changeSelect = function (e, buttons, wrapper) {
  const curr = e.target
  const val = curr.value

  if (buttons.length) {
    for (let i = 0; i < buttons.length; i++) {
      if (buttons[i].getAttribute('data-type-button') === val) {
        buttons[i].classList.add('button--active')
      } else {
        buttons[i].classList.remove('button--active')
      }
    }
  }

  isActive = true
  const postType = curr.getAttribute('data-post-type-button')
  const taxonomy = curr.getAttribute('data-taxonomy')

  getPosts({
    action: 'vacancies_list',
    termType: val,
    postType,
    taxonomy
  }, wrapper, cb)
}

docReady(() => {
  const typeButtons = document.querySelectorAll('[data-type-button]')
  const vacancies = document.getElementById('vacatures')
  const vacancySelect = document.getElementById('vacancy-select')
  const categorySelect = document.getElementById('category-select')

  if (vacancySelect && vacancies) {
    const changeHandler = e => changeSelect(e, typeButtons, vacancies)
    vacancySelect.addEventListener('change', changeHandler)
  }

  if (typeButtons.length && vacancies) {
    for (let i = 0; i < typeButtons.length; i++) {
      const clickHandler = e => changeType(e, typeButtons, i, vacancies)
      typeButtons[i].addEventListener('click', clickHandler)
    }
  }

  if (categorySelect) {
    categorySelect.addEventListener('change', function (e) {
      const href = e.target.value
      window.location.href = href
    })
  }
})
